/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:41600700-8d80-4276-8277-cf467db0a03e",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_VbAi5WZ1l",
    "aws_user_pools_web_client_id": "264atqlcqs9o44108olrs3s9ob",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "s3uploader4eircomimages14147-dev",
    "aws_user_files_s3_bucket_region": "us-west-2",
    "aws_content_delivery_bucket": "s3uploaderui-20240326014805-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "https://do22qlloxb1t1.cloudfront.net"
};


export default awsmobile;
